import { useEffect, useState } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { useGlobalState } from '../../Globals';
import logo from '../../Assets/img/auto-logo.jpg'
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export default function Begin() {
    const { handleStep, previousStep, nextStep, isLoading, isFirstStep } = useWizard();
    const [customerName, setCustomerName] = useGlobalState('customerName');
    const [companyName, setCompanyName] = useGlobalState('companyName');
    const { t, i18n } = useTranslation();

    const GoToNext = () => {
        let valid = true
        if (customerName == '' || customerName == undefined) {
            valid = false;
        }
        if (companyName == '' || companyName == undefined) {
            valid = false;
        }

        if (valid)
            nextStep();
        else
            console.log('You have empty fields.')
    }

    console.log()

    return (
        <div className="fixed w-full">
            <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none  bg-gray-light">
                <div className="relative flex-grow w-auto  mx-auto max-w-4xl mt-[100px] lg:mt-[200px] bg-gray-light border-solid border-slate-200 rounded-t">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex flex-col p-5 rounded-t border-b border-gray mx-5 text-center place-content-center items-center">
                            <img src={logo} />
                            <label className='font-bold text-[20px]'>{t("begin.title")}</label>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto min-h-[300px]">
                            <div className='flex flex-col w-full'>
                                <div className='flex-col p-2 px-20'>
                                    <label className='block text-[16px] p-3'>{t("begin.name")}</label>
                                    < input className='outline-none border border-gray rounded p-2 px-5 block w-full' value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
                                </div>
                                <div className='flex-col p-2 px-20'>
                                    <label className='block text-[16px] p-3'>{t("begin.company")}</label>
                                    <input className='outline-none border border-gray rounded p-2 px-5 block w-full' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                </div>
                            </div>
                        </div >
                        {/*footer*/}
                        < div className="flex items-center border-t border-gray mx-5 py-3">
                            <div className='flex-grow'>
                                <button className='bg-red text-white p-2 px-5 m-1 rounded disabled:bg-gray disabled:cursor-not-allowed disabled:text-gray-dark' disabled={isLoading || isFirstStep} onClick={() => previousStep()}>{t("general.previous")}</button>

                            </div>
                            <div className=''>
                                <button className='bg-red text-white p-2 px-5 m-1 rounded' onClick={() => GoToNext()}>{t("general.next")}</button>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        </div>
    );
}