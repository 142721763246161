import { useEffect, useState } from "react";
import axios from 'axios';

export default function Details(props) {
    const [showModal, setShowModal] = useState(false);
    const [list, setList] = useState([])
    const [surveyId, setSurveyId] = useState(props.surveyId ? props.surveyId : '')
    const [secret, setSecret] = useState(props.secret ? props.secret : '')
    const base_url = process.env.REACT_APP_BASEURL

    const getDetails = () => {
        if (surveyId != null) {
            axios.post(base_url + "/getSurvey",
                {
                    id: surveyId,
                    secret,
                })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.length > 0) {
                            setList(response.data[0].questions)
                        }
                        else {
                            console.log(response)
                        }
                    }
                    else
                        console.log(response);
                });
        }
    }

    useEffect(() => {
        getDetails()
    }, [])
    return (
        <>
            <button className="bg-blue p-2 rounded mr-1" onClick={() => { setShowModal(true); getDetails() }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>
            </button>
            {showModal ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-40"
                            onClick={() => setShowModal(false)}
                        >

                        </div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-4xl p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3 flex flex-col">
                                    <div className="flex flex-row items-center mx-auto bg-red-100 rounded-full">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-7 h-7 mt-1" viewBox="0 0 20 20" fill="currentColor">
                                            <path
                                                fillRule="evenodd"
                                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <h4 className="text-lg text-gray-800 ml-2">Survey of <span className="font-bold">{props.customerName}</span></h4>
                                    </div>
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left w-full mb-4">
                                        <div className="w-full max-h-[300px] overflow-y-auto">
                                            <table className="w-full">
                                                <thead>
                                                    <tr className="border-b border-gray-light p-3">
                                                        <th className="p-2">#</th>
                                                        <th className="p-2 hidden">Question</th>
                                                        <th className="p-2">Title</th>
                                                        <th className="p-2">Answer</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        list
                                                            ? list.map((x, index) => {
                                                                return <tr key={index}>
                                                                    <td className="p-2 border-b border-gray-light">{index + 1}</td>
                                                                    <td className="p-2 border-b border-gray-light hidden">{x.surdQuestion}</td>
                                                                    <td className="p-2 border-b border-gray-light">{x.surdDesc}</td>
                                                                    <td className="p-2 border-b border-gray-light">{x.surdAnswer}</td>
                                                                </tr>
                                                            })
                                                            : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="items-center justify-end gap-2 mt-3 sm:flex">
                                        <button
                                            className="mt-2 px-3 py-1 bg-red text-white rounded-md outline-none hover:opacity-80"
                                            onClick={() =>
                                                setShowModal(false)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}