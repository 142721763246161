import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from "react";
import logo from '../../Assets/img/auto-logo.jpg'

export default function Survey() {
    const [list, setList] = useState([])
    const [customer, setCustomer] = useState('')
    const [company, setCompany] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id")
    const secret = searchParams.get("se")
    const base_url = process.env.REACT_APP_BASEURL
    const navigate = useNavigate();

    const getSurvey = () => {
        axios.post(base_url + "/getSurvey",
            {
                id,
                secret,
            })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        setCustomer(response.data[0].customer)
                        setCompany(response.data[0].company)
                        setList(response.data[0].questions)
                    }
                    else {
                        console.log(response)
                    }
                }
                else
                    console.log(response);
            });
    }

    useEffect(() => {
        if (id != null && secret != null)
            getSurvey()
        else
            navigate('/')
    }, [])
    return (
        <div className="fixed w-full">
            <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-light">
                <div className="relative flex-grow w-auto  mx-10 my-10 bg-gray-light border-solid border-slate-200 rounded-t">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-5">
                        {/*header*/}
                        <div className="flex flex-col flex-grow p-5 rounded-t border-b border-gray mx-5 text-center items-center">
                            <img src={logo} />
                            <label className='font-bold text-[20px]'>Results</label>
                        </div>
                        <div className="flex flex-col p-5 rounded-t border-b border-gray mx-5">
                            <label className='text-[20px]'><span className="font-bold">Customer Name: </span>{customer}</label>
                            <label className='text-[20px]'><span className="font-bold">Company Name: </span>{company}</label>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto min-h-[300px]">
                            {
                                list
                                    ? list.map((x, index) => {
                                        return (
                                            <div key={index} className='flex flex-row mx-10 py-2 border-b border-gray-light'>
                                                <div className=''><span className='font-bold'>{x.surdQuestion}:</span> {x.surdDesc}</div>
                                                <div className='mx-5 font-bold'>{x.surdAnswer}</div>
                                            </div>
                                        )
                                    })
                                    : null

                            }
                        </div >
                    </div >
                </div >
            </div >
        </div>
    )
}