import { createGlobalState } from "react-hooks-global-state";
import enFlag from "../../Assets/img/united-states.png";
import frFlag from "../../Assets/img/france.png";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Wizard, useWizard } from 'react-use-wizard';
import { useGlobalState } from '../../Globals';

function Language() {
    const { handleStep, previousStep, nextStep, isLoading, isFirstStep } = useWizard();
    const [answerList, setAnswerList] = useGlobalState('answerList');
    const { t, i18n } = useTranslation();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [language, setLanguage] = useState(frFlag);
    const [languageChoosen, setLanguageChoosen] = useState('');

    function handleCookie(e) {
        setCookie("i18next", e, {
            expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
        });
        i18n.changeLanguage(e);
        handleChangeLanguage(e);
    }

    const handleChangeLanguage = (e) => {
        if (e == "en") {
            setLanguage(enFlag);
        } else {
            setLanguage(frFlag);
        }
    };

    const languageChanged = () => {
        if (cookies.i18next == "en") {
            setLanguageChoosen('Your language is set to English. Press next to start.')
            setLanguage(enFlag);
        }
        else {
            setLanguageChoosen('La langue choisie est le français. Appuyez sur suivant pour commencer.')
            setLanguage(frFlag);
        }
    }

    useEffect(() => {
        languageChanged()
    });

    return (
        <div className="fixed w-full">
            <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none  bg-gray-light">
                <div className="relative flex-grow w-auto  mx-auto max-w-4xl mt-[100px] lg:mt-[200px] bg-gray-light border-solid border-slate-200 rounded-t">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex flex-col p-5 rounded-t border-b border-gray mx-5 text-center place-content-center items-center">
                            <label className="italic font-bold">Choose a language before starting/ Choisissez une langue avant de commencer </label>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto min-h-[300px]">
                            <div className='flex flex-col w-full  min-h-[300px] place-content-center items-center'>
                                <div className={"flex flex-col bg-white m-auto top-10 place-content-center items-center rounded shadow"}>
                                    <div className="flex flex-row p-2 cursor-pointer" onClick={() => handleCookie("fr")}>
                                        <img src={frFlag} width="25" height="25" />
                                        <span className="px-1">Francais</span>
                                    </div>
                                    <div className="flex flex-row p-2 cursor-pointer" onClick={() => handleCookie("en")}>
                                        <img src={enFlag} width="25" height="25" />
                                        <span className="px-1">English</span>
                                    </div>
                                </div>
                                <div className="pb-2 font-bold italic">
                                    {languageChoosen}
                                </div>
                            </div>
                        </div >
                        {/*footer*/}
                        < div className="flex items-center border-t border-gray mx-5 py-3">
                            <div className='flex-grow'>
                                <button className='bg-red text-white p-2 px-5 m-1 rounded disabled:bg-gray disabled:cursor-not-allowed disabled:text-gray-dark' disabled={isLoading || isFirstStep}>{t("general.previous")}</button>

                            </div>
                            <div className=''>
                                <button className='bg-red text-white p-2 px-5 m-1 rounded' onClick={() => { setAnswerList([]); nextStep() }}>{t("general.next")}</button>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        </div>
    );
}

export default Language;
