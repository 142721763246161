import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState, useRef } from "react";
import logo from '../../Assets/img/auto-logo.jpg'
import moment from 'moment';
import Header from '../Layout/Header';
import Details from "./Details";
import { useDownloadExcel } from 'react-export-table-to-excel';

export default function List() {
    const base_url = process.env.REACT_APP_BASEURL
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const secret = searchParams.get("k")
    const [list, setList] = useState([])
    const [totalSurvey, setTotalSurvey] = useState(0)
    const [totalReadSurvey, setTotalReadSurvey] = useState(0)
    const tableRef = useRef(null);
    const [exportData, setExportData] = useState([])

    const getSurveyList = () => {
        axios.post(base_url + "/getSurveyList")
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        setList(response.data)
                    }
                    else {
                        console.log(response)
                    }
                }
                else
                    console.log(response);
            });
    }

    const getTotalSurvey = () => {
        axios.post(base_url + "/getTotalSurvey")
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        setTotalSurvey(response.data[0].total)
                    }
                    else {
                        console.log(response)
                    }
                }
                else
                    console.log(response);
            });
    }

    const getTotalReadSurvey = () => {
        axios.post(base_url + "/getTotalReadSurvey")
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        setTotalReadSurvey(response.data[0].total)
                    }
                    else {
                        console.log(response)
                    }
                }
                else
                    console.log(response);
            });
    }

    const getFullSurvey = () => {
        axios.post(base_url + "/getFullSurvey")
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        setExportData(response.data)
                    }
                    else {
                        console.log(response)
                    }
                }
                else
                    console.log(response);
            });
    }

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Client Survey',
        sheet: 'survey'
    })

    useEffect(() => {
        if (secret != null & secret === process.env.REACT_APP_SECRET) {
            getSurveyList()
            getTotalSurvey()
            getTotalReadSurvey()
            getFullSurvey()
        }
        else
            navigate('/')
    }, [])

    return (
        <div className="flex flex-col w-full min-h-screen bg-gray-light">
            <Header />
            <div className="flex w-full items-center justify-center ">
                <div className="bg-white rounded flex flex-col m-2 mx-10 w-[200px] mt-[100px] p-5">
                    <div className="text-[20px] font-bold">
                        Total Survey
                    </div>
                    <div className="text-right text-[40px] font-bold flex flex-row">
                        <div className="flex-grow">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 mt-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                            </svg>
                        </div>
                        <div>
                            {totalSurvey}
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded flex flex-col m-2 mx-10 w-[200px] mt-[100px] p-5">
                    <div className="text-[18px] font-bold">
                        Total Survey Read
                    </div>
                    <div className="text-right text-[40px] font-bold flex flex-row">
                        <div className="flex-grow">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 mt-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                            </svg>
                        </div>
                        <div>
                            {totalReadSurvey}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-5">
                <div className="w-full shadow bg-white px-10 overflow-y-auto pb-10">
                    <div className="flex flex-row">
                        <h3 className="font-bold block text-[18px] p-3 text-gray-dark flex-grow">List of Survey</h3>
                        <button type="button" onClick={onDownload} className="p-2 justify-center vertical-center bg-green text-white m-1 text-[16px] min-w-[75px] rounded">Export</button>
                    </div>
                    <table className="w-full">
                        <thead>
                            <tr className="text-left border-b border-b-gray">
                                <th className="font-bold p-3">Survey Id</th>
                                <th className="font-bold p-3">Company Name</th>
                                <th className="font-bold p-3">Customer Name</th>
                                <th className="font-bold p-3">Created Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list
                                    ? list.map((x, index) => {
                                        return <tr key={index} className='text-left border-b border-b-gray'>
                                            <td className="p-3">{x.surId}</td>
                                            <td className="p-3">{x.surCompanyName}</td>
                                            <td className="p-3">{x.surCustomerName}</td>
                                            <td className="p-3">{moment(x.surCreatedDate).format('YYYY-MM-DD')}</td>
                                            <td className="p-3">
                                                <Details surveyId={x.surId} customerName={x.surCustomerName} secret={x.surSecret} />
                                                {
                                                    x.surRead === 0
                                                        ? <button className="bg-red p-2 rounded">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                                                            </svg>
                                                        </button>
                                                        : null
                                                }
                                            </td>
                                        </tr>
                                    })
                                    : null
                            }
                        </tbody>
                    </table>

                    <table className="hidden" ref={tableRef}>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Comapny Name</th>
                                <th>Crated Date</th>
                                <th>Q1</th>
                                <th>Q2</th>
                                <th>Q3</th>
                                <th>Q4</th>
                                <th>Q5</th>
                                <th>Q6</th>
                                <th>Q7</th>
                                <th>Q8</th>
                                <th>Q9</th>
                                <th>Q10</th>
                                <th>Q11</th>
                                <th>Q12</th>
                                <th>Q13</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                exportData
                                    ? exportData.map((x, index) => {
                                        return <tr key={index}>
                                            <td>{x.Id}</td>
                                            <td>{x.Name}</td>
                                            <td>{x.Company}</td>
                                            <td>{x.Date}</td>
                                            <td>{x.Q1}</td>
                                            <td>{x.Q2}</td>
                                            <td>{x.Q3}</td>
                                            <td>{x.Q4}</td>
                                            <td>{x.Q5}</td>
                                            <td>{x.Q6}</td>
                                            <td>{x.Q7}</td>
                                            <td>{x.Q8}</td>
                                            <td>{x.Q9}</td>
                                            <td>{x.Q10}</td>
                                            <td>{x.Q11}</td>
                                            <td>{x.Q12}</td>
                                            <td>{x.Q13}</td>
                                        </tr>
                                    })
                                    : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}