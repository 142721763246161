import { useEffect, useState } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { useGlobalState } from '../../Globals';
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";

export default function Question9() {
    const { t, i18n } = useTranslation();
    const question = 'Question9';
    const desc = t("question.question9")
    const { handleStep, previousStep, nextStep } = useWizard();
    const [answerList, setAnswerList] = useGlobalState('answerList');
    const found = answerList.find(obj => {
        return obj.key === question
    })
    const [selected, setSelected] = useState(found ? found.answer : 0);

    const ratingChanged = (newRating) => {
        setSelected(newRating)
    };

    handleStep(() => {
        const found = answerList.find(obj => {
            return obj.key === question
        })
        if (found === null || found === undefined) {
            answerList.push({
                key: question,
                desc: desc,
                answer: selected,
            });
        }
        else {
            const found = answerList.map((obj) => {
                if (obj.key === question)
                    return { ...obj, answer: selected }
                return { ...obj }
            })
            setAnswerList(found)
        }
    });

    const GoToNext = () => {
        let valid = true
        if (selected == 0 || selected == undefined) {
            valid = false;
        }

        if (valid)
            nextStep();
        else
            console.log('You should answer ' + question + '.')
    }

    return (
        <div className="fixed w-full">
            <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none  bg-gray-light">
                <div className="relative flex-grow w-auto  mx-auto max-w-4xl mt-[100px] lg:mt-[200px] bg-gray-light border-solid border-slate-200 rounded-t">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex p-5 rounded-t border-b border-gray mx-5">
                            <h3 className="text-2xl font-semibold w-full">
                                Question 9
                            </h3>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto min-h-[300px]">
                            <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-10 ml-2 font-bold text-[18px]">
                                {desc}
                            </div>
                            <div className='flex flex-col w-full inline-block'>
                                <div className='flex-1 p-2 px-10'>
                                    <ReactStars
                                        count={10}
                                        onChange={ratingChanged}
                                        size={48}
                                        activeColor="#ffd700"
                                        color={''}
                                        value={parseInt(selected)}
                                    />
                                </div>
                            </div>
                        </div >
                        {/*footer*/}
                        < div className="flex items-center border-t border-gray mx-5 py-3">
                            <div className='flex-grow'>
                                <button className='bg-gray-light text-gray-dark p-2 px-5 m-1 rounded' onClick={() => previousStep()}>{t("general.previous")}</button>
                            </div>
                            <div className=''>
                                <button className='bg-red text-white p-2 px-5 m-1 rounded' onClick={() => GoToNext()}>{t("general.next")}</button>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        </div>
    );
}