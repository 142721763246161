import logo from '../../Assets/img/logo.jpg';

export default function Header() {

    return (
        <div className="fixed bg-white h-[75px] z-10 w-full flex">
            <div className='flex-grow'>
                <img src={logo} className='max-h-[75px] lg:ml-10' />
            </div>
        </div>
    )
}